import React from 'react';
import { Link } from "gatsby";

// Shape Images
import Shape1 from "../../assets/images/shape2.svg";
import Shape2 from "../../assets/images/shape2.svg";
import Shape3 from "../../assets/images/shape4.svg";
import Shape4 from "../../assets/images/shape4.svg";

const PricingStyleOne = () => {
    return (
        <div className="pricing-area pt-80 pb-50 bg-f9f6f6">
            <div className="container">
                <div className="section-title">
                    <h2>Çalışma Şekilleri</h2>
                    <div className="bar"></div>
                    <p>Pixel Yazılım sizlere özel hizmetleri verirken gizliliğe ve proje üretmeye öncelik verir. Firmanıza uygun yazılım projesi üretir ve size özel çalışma şekilleriyle size uyum sağlar</p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                        <div className="pricing-table">
                            <div className="pricing-header">
                                <h3>ÖZEL YAZILIM</h3>
                            </div>

                            <div className="content">
                                <div className="price">
                                    <span><sup></sup><span>Şirketinize ihtiyaç olan yazılımları</span></span>
                                </div>
                                
                                <div className="pricing-features">
                                    <ul>
                                        <li className="active">Ücretsiz analiz edip</li>
                                        <li className="active">Tekliflendiriyor</li>
                                        <li className="active">Çalışma şeklinize uyum sağlayacak şekilde </li>
                                        <li className="active">Yazılıma Başlıyoruz</li>
                                        <li className="active">Mobil Web Masaüstü </li>
                                        <li className="active">Platformlarda</li>
                                        <li className="active">Teslim ediyoruz</li>
                                        <li className="active">1 yıl ücretsiz destek veriyoruz</li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>
                                
                                <div className="pricing-footer">
                                    <Link to="/proje-talebi" className="btn btn-primary">
                                        TALEP BAŞLAT
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                        <div className="pricing-table active-plan">
                            <div className="pricing-header">
                                <h3>GİRİŞİM PROJELERİ</h3>
                            </div>
                            
                            <div className="content">
                                <div className="price">
                                    <span><sup></sup><span>Geleceğe yatırım amaçlı fikirlerinizi</span></span>
                                </div>
                                
                                <div className="pricing-features">
                                    <ul>
                                        <li className="active">Ücretsiz analiz edip</li>
                                        <li className="active">Strateji belirliyor</li>
                                        <li className="active">Tekliflendirip başlıyoruz</li>
                                        <li className="active">Dilerseniz bütçemiz oranda ortak oluyoruz</li>
                                        <li className="active">Projenizin yazılım süreçlerini</li>
                                        <li className="active">Tamamen biz yürütüyoruz</li>
                                        <li className="active">Siz sadece reklam ve strateji yaparak</li>
                                        <li className="active">Fikrinizi hayata geçiriyorsunuz</li>
                                        
                                    </ul>
                                </div>
                                
                                <div className="pricing-footer">
                                    <Link to="/proje-talebi" className="btn btn-primary">
                                    TALEP BAŞLAT
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                        <div className="pricing-table">
                            <div className="pricing-header">
                                <h3>SİSTEM ANALİZLERİ</h3>
                            </div>
                            
                            <div className="content">
                                <div className="price">
                                    <span><sup></sup><span>Firmanızda kriz olan</span></span>
                                </div>
                                
                                <div className="pricing-features">
                                    <ul>
                                        <li className="active">Çözüm için</li>
                                        <li className="active">Zaman ayırmak isteyip</li>
                                        <li className="active">İlgilenemediğiniz</li>
                                        <li className="active">Süreçleri analiz edip</li>
                                        <li className="active">Yazılımla veya </li>
                                        <li className="active">Farklı şekillerde</li>
                                        <li className="active">Çözüme kavuşturacak</li>
                                        <li className="active">Yollar sunup rapor ediyoruz</li>
                                    </ul>
                                </div>
                                
                                <div className="pricing-footer">
                                    <Link to="/proje-talebi" className="btn btn-primary">
                                    TALEP BAŞLAT
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape8 rotateme">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
        </div>
    );
    
}

export default PricingStyleOne;
